/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  CardFooter,
} from "reactstrap";
import axios from "axios";
import { editMode } from "helpers/Utils.js";
import StudentEdit2 from "components/Student/Student2.component.edit.jsx";
import * as dateFns from "date-fns";
import { SavePdf } from "components/Report/GeneratePdf.js";

class PendingSelfEnrollment extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      students: [],
      openModal: false,
    };
  };

  render() {
    let students = this.state.students.map((student) => {
      return (
        <tr key={student.id}>
          <td>{student.name}</td>
          <td>{student.i20}</td>
          <td>{student.plan}</td>
          <td>{student.semesterName}</td>
          <td>{student.campusName}</td>
          <td>{student.status}</td>
          <td>{student.createdDate}</td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>I-20</th>
                        <th>Plan</th>
                        <th>Semester</th>
                        <th>Campus</th>
                        <th>Status</th>
                        <th>Created Date</th>
                      </tr>
                    </thead>

                    <tbody>{students}</tbody>
                  </Table>
                </CardBody>

                <CardFooter>
                  <Row form style={{ display: "block" }}>
                    <Row>
                      {students && students.length > 0 && (
                        <h6
                          style={{
                            marginLeft: "25px",
                            marginRight: "auto",
                            marginTop: "15px",
                            textTransform: "inherit",
                          }}
                        >
                          <strong className="text-secondary">
                            {students.length}
                          </strong>{" "}
                          {students.length > 1 ? "records" : "record"}
                        </h6>
                      )}

                      {/* <Button
                        color="success"
                        size="sm"
                        style={{
                          marginLeft: "auto",
                          textTransform: "inherit",
                          marginRight: "10px",
                          marginBottom: "10px",
                        }}
                        onClick={this.DownloadPdf}
                      >
                        Export to PDF
                      </Button> */}

                      {/* <Button
                        color="success"
                        size="sm"
                        style={{
                          textTransform: "inherit",
                          marginRight: "30px",
                          marginBottom: "10px",
                        }}
                        onClick={this.DownloadCsv.bind(this)}
                      >
                        Export to CSV
                      </Button> */}
                    </Row>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  componentDidMount() {
    this._refreshStudents();
  }

  _refreshStudents() {
    return new Promise((resolve, reject) => {
      axios.get("dashboard/pending-self-enrollment").then((response) => {
        let mainState = this.state;

        mainState.students = [];
        response.data.data.map((student, index) =>
          mainState.students.push({
            id: student.id,
            name: student.name,
            i20: student.i20,
            email: student.email,
            plan: student.plan,
            semesterName: student.semesterName,
            campusName: student.campusName
              .replace("Salt Lake in Person", "SLC")
              .replace("Orem In Person", "Orem")
              .replace("Online Combined Campuses", "Online"),
            status: student.status,
            createdDate: dateFns.format(
              dateFns.parseISO(student.createdDate),
              "MM/dd/yyyy"
            ),
          })
        );

        this.setState(mainState, resolve(true));
      });
    });
  }

  DownloadPdf() {
    axios.get("dashboard/missing-self-enrollment").then((response) => {
      let students = [];
      response.data.data.map((student, index) =>
        students.push({
          name: student.name,
          email: student.email,
          cellPhone: student.cellPhone,
          i20: student.i20,
          status: student.status,
        })
      );

      SavePdf("Missing Self-Enrollment", students, [
        {
          name: "Name",
          email: "Email",
          cellPhone: "Cell Phone",
          i20: "I20",
          status: "Status",
        },
      ]);
    });
  }

  DownloadCsv() {
    axios.get("dashboard/missing-self-enrollment").then((response) => {
      let students = [];
      response.data.data.map((student, index) =>
        students.push({
          name: student.name,
          email: student.email,
          cellPhone: student.cellPhone,
          i20: student.i20,
          status: student.status,
        })
      );

      let studentCsv = [];

      students.forEach((item) => {
        studentCsv.push({
          Name: item.name,
          Email: item.email,
          CellPhone: item.cellPhone,
          I20: item.i20,
          Status: item.status,
        });
      });

      this.downloadCSVFromJson("MissingSelfEnrollment.csv", studentCsv);
    });
  }

  downloadCSVFromJson(filename, arrayOfJson) {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(arrayOfJson[0]);
    let csv = arrayOfJson.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(";")
    );
    csv.unshift(header.join(";"));
    csv = csv.join("\r\n");

    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export default PendingSelfEnrollment;
