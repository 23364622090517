/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import { Multiselect } from "multiselect-react-dropdown";
import StudentClassView from "components/Student/Student.class.view";

const objectArrayConst = [
  { id: "1", value: "Self-enrollments" },
  { id: "2", value: "Enrollments" },
  { id: "3", value: "Applications" },
];

class MapReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      grid_SLC: [],
      grid_ORE: [],
      grid_Total: [],
      subjects: [],
      selectedMapTypes: [],
      objectArray: objectArrayConst,
      selectedValues: objectArrayConst.filter((x) =>
        ["1", "2", "3"].includes(x.id)
      ),
      studentModalIsOpen: false,
      selectedClassId: "",
    };
  };

  onSelect(selectedList, selectedItem) {
    let mainState = this.state;
    mainState.selectedMapTypes.push(selectedItem);
    this.setState(mainState, () => {
      this._refreshSelfEnrollmentGroup();
    });
  }

  onRemove(selectedList, removedItem) {
    let mainState = this.state;

    let newselectedMapTypes = mainState.selectedMapTypes.filter(
      (x) => x.id !== removedItem.id
    );

    mainState.selectedMapTypes = newselectedMapTypes;

    this.setState(mainState, () => this._refreshSelfEnrollmentGroup());
  }

  render() {
    const gridSLC = (
      <ReactDataSheet
        data={this.state.grid_SLC}
        valueRenderer={(cell) => cell.value}
      />
    );

    const gridORE = (
      <ReactDataSheet
        data={this.state.grid_ORE}
        valueRenderer={(cell) => cell.value}
      />
    );

    const gridTotal = (
      <ReactDataSheet
        data={this.state.grid_Total}
        valueRenderer={(cell) => cell.value}
      />
    );

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Col md="12" style={{ paddingLeft: "0px" }}>
                <Card>
                  <CardHeader>
                    <Row form>
                      <Col md={5}>
                        <Label>Type</Label>
                        <Multiselect
                          options={this.state.objectArray}
                          onSelect={this.onSelect.bind(this)}
                          onRemove={this.onRemove.bind(this)}
                          displayValue="value"
                          selectedValues={this.state.selectedValues}
                        />
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    {gridSLC}

                    <br />

                    {gridORE}
                    <br />

                    {gridTotal}
                  </CardBody>
                </Card>
              </Col>
            </Col>
          </Row>
        </div>

        <StudentClassView
          url={"students/byclassgroup?classGroup="}
          searchId={this.state.selectedClassId}
          isOpen={this.state.studentModalIsOpen}
          toggleStudentModal={this.toggleStudentClassViewModal.bind(
            this,
            this.state.selectedClassId
          )}
          tables={this.props.tables}
          headerName={this.state.selectedClassId}
        />
      </>
    );
  }

  componentDidMount() {
    this.setState({ selectedMapTypes: this.state.selectedValues }, () =>
      this._refreshClasses().then(() => {
        this._refreshSelfEnrollmentGroup();
      })
    );
  }

  _refreshSelfEnrollmentGroup() {
    let mapTypeList = [];
    this.state.selectedMapTypes
      .sort((a, b) => a.id - b.id)
      .map((mapType, index) => mapTypeList.push(mapType.id));

    let getAllParam = {
      mapTypeList,
    };

    return new Promise((resolve, reject) => {
      axios
        .post("selfEnroll/GetGroupSelfEnroll", getAllParam)
        .then((response) => {
          let mainState = this.state;

          mainState.grid_SLC = [];
          mainState.grid_ORE = [];
          mainState.grid_Total = [];

          mainState.grid_SLC.push([
            { readOnly: true, value: "Name" },
            { readOnly: true, value: "Intro" },
            { readOnly: true, value: "Level 1" },
            { readOnly: true, value: "Level 2" },
            { readOnly: true, value: "Level 3" },
            { readOnly: true, value: "Level 4" },
            { readOnly: true, value: "Level 5" },
            { readOnly: true, value: "Level 6" },
            { readOnly: true, value: "Toefl" },
            { readOnly: true, value: "Total" },
          ]);

          mainState.grid_ORE.push([
            { readOnly: true, value: "Name" },
            { readOnly: true, value: "Intro" },
            { readOnly: true, value: "Level 1" },
            { readOnly: true, value: "Level 2" },
            { readOnly: true, value: "Level 3" },
            { readOnly: true, value: "Level 4" },
            { readOnly: true, value: "Level 5" },
            { readOnly: true, value: "Level 6" },
            { readOnly: true, value: "Toefl" },
            { readOnly: true, value: "Total" },
          ]);

          // mainState.grid_Total.push([
          //   { readOnly: true, value: "Name" },
          //   { readOnly: true, value: "Intro" },
          //   { readOnly: true, value: "Level 1" },
          //   { readOnly: true, value: "Level 2" },
          //   { readOnly: true, value: "Level 3" },
          //   { readOnly: true, value: "Level 4" },
          //   { readOnly: true, value: "Level 5" },
          //   { readOnly: true, value: "Level 6" },
          //   { readOnly: true, value: "Toefl" },
          //   { readOnly: true, value: "Total" },
          // ]);

          this.mapReport(response, mainState.grid_SLC, "SLC");
          this.mapReport(response, mainState.grid_ORE, "ORE");
          this.mapReport(response, mainState.grid_Total, "Total");

          this.setState(mainState, resolve(true));
        });
    });
  }

  mapReport(response, grid, campus) {
    response.data.data
      .filter(
        (x) =>
          x.Name.substring(0, 3) == campus ||
          (x.Name.substring(0, 5) == "Total" && campus == "Total")
      )
      .map((se, index) =>
        grid.push([
          { readOnly: true, value: se.Name },
          {
            readOnly: se.Name === "Total",
            value:
              se.Name === "Total" ? se.Intro : this.transformValue(0, se.Intro),
            className: se.Name !== "Total" && this.getClassName(0, se.Intro),
          },
          {
            readOnly: se.Name === "Total",
            value: this.transformValue(1, se.Level1),
            className: se.Name !== "Total" && this.getClassName(1, se.Level1),
            forceComponent: true,
            component:
              se.Level1 > 0 && this.getTooltip(se.Name, se.Level1, 1, index),
          },
          {
            readOnly: se.Name === "Total",
            value: this.transformValue(2, se.Level2),
            className: se.Name !== "Total" && this.getClassName(2, se.Level2),
            forceComponent: true,
            component:
              se.Level2 > 0 && this.getTooltip(se.Name, se.Level2, 2, index),
          },
          {
            readOnly: se.Name === "Total",
            value: this.transformValue(3, se.Level3),
            className: se.Name !== "Total" && this.getClassName(3, se.Level3),
            forceComponent: true,
            component:
              se.Level3 > 0 && this.getTooltip(se.Name, se.Level3, 3, index),
          },
          {
            readOnly: se.Name === "Total",
            value: this.transformValue(4, se.Level4),
            className: se.Name !== "Total" && this.getClassName(4, se.Level4),
            forceComponent: true,
            component:
              se.Level4 > 0 && this.getTooltip(se.Name, se.Level4, 4, index),
          },
          {
            readOnly: se.Name === "Total",
            value: this.transformValue(5, se.Level5),
            className: se.Name !== "Total" && this.getClassName(5, se.Level5),
            forceComponent: true,
            component:
              se.Level5 > 0 && this.getTooltip(se.Name, se.Level5, 5, index),
          },
          {
            readOnly: se.Name === "Total",
            value: this.transformValue(6, se.Level6),
            className: se.Name !== "Total" && this.getClassName(6, se.Level6),
            forceComponent: true,
            component:
              se.Level6 > 0 && this.getTooltip(se.Name, se.Level6, 6, index),
          },
          {
            readOnly: se.Name === "Total",
            value: this.transformValue(7, se.Toefl),
            className: se.Name !== "Total" && this.getClassName(7, se.Toefl),
            forceComponent: true,
            component:
              se.Toefl > 0 && this.getTooltip(se.Name, se.Toefl, 7, index),
          },
          { readOnly: true, value: se.Total },
        ])
      );
  }

  transformValue(level, value) {
    let course = this.props.tables.courses.find((x) => x.order == level);

    return value == 0 ? "" : value + " (" + course.maxSize + ")";
  }

  getClassName(level, value) {
    let course = this.props.tables.courses.filter((x) => x.order == level)[0];

    if (course) {
      return value == 0
        ? ""
        : value < course.minSize
        ? "mapCellRed"
        : value >= course.minSize && value <= course.maxSize
        ? "mapCellGreen"
        : "mapCellYellow";
    }
    return "";
  }

  _refreshClasses = () => {
    return new Promise((resolve, reject) => {
      axios.get("classes?page=1&pageSize=999999").then((response) => {
        let classes = [];

        response.data.data.items.map((classe, index) =>
          classes.push({
            id: classe.id,
            code: classe.code,
            campusName: classe.campus.name
              .replace("Salt Lake in Person", "SLC")
              .replace("Orem In Person", "OREM"),
            teacherName: classe.teacher.name,
            block: classe.block,
            period: classe.period,
            order: classe.course.order,
            groupClassName: classe.groupClass.name,
          })
        );

        this.setState({ classes: classes }, resolve(true));
      });
    });
  };

  getTeachers(groupClassName) {
    let subjects = this.state.classes.filter(
      (x) => x.groupClassName.toUpperCase() == groupClassName.toUpperCase()
    );

    let result = subjects.map((item, index) => {
      return (
        <React.Fragment>
          <label style={{ margin: "0" }}>{item.code}</label>
          <label style={{ margin: "0" }}>{item.teacherName}</label>
          <br />
        </React.Fragment>
      );
    });

    return result;
  }

  getTooltip(name, value, level, index) {
    let course = this.props.tables.courses.find((x) => x.order == level);

    let classId = "";

    if (name.includes("MT") || name.includes("WT")) {
      classId =
        (level === 7 ? "T" : level) +
        "-" +
        name.substring(4, 5) +
        name.substring(5, 6) +
        " " +
        name.substring(7, 8) +
        " " +
        (name.includes("ORE") ? "Orem" : name.includes("SLC") ? "SLC" : name);
    } else {
      classId =
        (level === 7 ? "T" : level) +
        "-" +
        name.substring(4, 5) +
        name.substring(6, 7) +
        " " +
        (name.includes("ORE") ? "Orem" : name.includes("SLC") ? "SLC" : name);
    }

    let id = `id-${Math.random().toString(36).substr(2, 9)}`;

    return (
      <div id={id}>
        <span
          className="value-viewer"
          onClick={this.toggleStudentClassViewModal.bind(this, classId)}
        >
          {value + (name === "Total" ? "" : " (" + course.maxSize + ")")}
        </span>

        {name !== "Total" && (
          <UncontrolledTooltip target={id} placement="right">
            {this.getTeachers(classId)}
          </UncontrolledTooltip>
        )}
      </div>
    );
  }

  toggleStudentClassViewModal = (classId) => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.studentModalIsOpen = !mainState.studentModalIsOpen;
      mainState.selectedClassId = classId;

      this.setState(mainState, resolve(true));
    });
  };
}

export default MapReport;
